.content-bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -10;
    background-image: url('../../theme/img/content-bg.png'); 
    background-repeat: no-repeat; 
    background-attachment: scroll; 
        
        /* Arranging the image */ 
    height: 100%; 
    width: 100%;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    text-align: justify;
    mix-blend-mode: hard-light;
    opacity: 0.5;
}

#content-output-textarea > p > h1 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 20px;
}

#content-output-textarea > h1 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 20px;
}

#content-output-textarea > p > h2 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
}

#content-output-textarea > p > h3 {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
}

#content-output-textarea > p > p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
}

#content-output-textarea > p > header > h1 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 20px;
}

#content-output-textarea > p > section > h2 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
}

#content-output-textarea > p > section > p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
}

#content-output-textarea > p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
}

#content-output-textarea > p > article > h1 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 20px;
}

#content-output-textarea > p > article > h2 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
}

#content-output-textarea > p > article > p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
}


