/* :root {
  --input-bg: #19192B;
} */

/* Boilerplate for body styles */
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Satoshi';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom styles */

.input-bg {
  background: var(--input-bg);
  border: 1px solid #535B71;
}

input:focus-visible, select:focus-visible, textarea:focus-visible {
  background: linear-gradient(#19192b, #19192b) 50% 50%/calc(100% - 2px) calc(100% - 2px) no-repeat,
              linear-gradient(#ff3aeb 0%, #6c2bd9 100%);
  border-radius: 0px;
  box-sizing: border-box;
  outline: none;
}

input {
  background: transparent
}



/* Tailwind */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-Bold.otf) format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-Italic.otf) format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-BoldItalic.otf) format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-Light.otf) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-LightItalic.otf) format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-MediumItalic.otf) format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-Black.otf) format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./theme/fonts/Satoshi-BlackItalic.otf) format('opentype');
  font-weight: 900;
  font-style: italic;
}