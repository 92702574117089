.slider {
    width: 100%;
    margin-bottom: 16px;    
}

@media screen and (min-width: 768px) {
    .slider {
        width: 75%;
    }
}

@media screen and (min-width: 1268px) {
    .slider {
        width: 75%;
    }
}

.slider-thumb {
    background: url(../theme/img/slider-thumb.png) no-repeat;
    height: 21px;
    width: 21px;
    top: -3px;
}

.slider-track {
    background: #19192B;
    height: 14px;
    border: 1px solid #2A2734;
    border-radius: 40px;
}