.swiper-pagination-horizontal {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    margin-left: 12px;
}

.swiper-pagination-bullet {
    width: 22px;
    height: 22px;
    background-color: #313154;
    margin: 0;
    margin-right: 25px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    bottom: 15px;
    margin-top: 53px;
}

.swiper-pagination-bullet-active {
    background-color: #0093ff;
}