.create-slider {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;    
    height: 16px;
}

.create-slider-slider-thumb {
    background: url(../theme/img/slider-thumb.png) no-repeat;
    height: 21px;
    width: 21px;
    top: -3px;
}

.create-slider-slider-track {
    background: #19192B;
    height: 14px;
    border: 1px solid #2A2734;
    border-radius: 40px;
}